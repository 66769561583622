import React, { useState } from "react";
import { Box, Grid, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { stringIsValidEmail } from "../utils/validation";
import cloneDeep from "lodash.clonedeep";
import set from "lodash.set";
import { networkRequest } from "../utils/network-request";
import Alert from "@material-ui/lab/Alert";

// styles
const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
}));

// validate form valus
const validateContactFormValues = (values) => {
  const errors = {};
  if (!values.name.value) {
    errors.name = "Please add your name!";
  }
  if (!values.email.value) {
    errors.email = "Please add your email address!";
  }
  if (values.email.value) {
    if (!stringIsValidEmail(values.email.value)) {
      errors.email = "Email appears to be invalid!";
    }
  }
  if (!values.message.value) {
    errors.message = "Please add your message!";
  }
  return errors;
};

// default form state
const defaultState = {
  loading: false,
  error: null,
  success: null,
  fields: {
    name: {
      error: null,
      value: "",
    },
    email: {
      error: null,
      value: "",
    },
    message: {
      error: null,
      value: "",
    },
  },
};

// component
export const ContactForm = () => {
  const [localState, setLocalState] = useState(defaultState);
  const { formField } = useStyles();
  const updateState = (array) => {
    const newState = cloneDeep(localState);
    let i = 0;
    const imax = array.length;
    for (; i < imax; ++i) {
      const path = array[i][0];
      const value = array[i][1];
      set(newState, path, value);
    }
    setLocalState(newState);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateContactFormValues(localState.fields);
    const setErrors = [];
    for (const key in errors) {
      setErrors.push([`fields.${key}.error`, errors[key]]);
    }
    if (setErrors.length > 0) {
      return updateState(setErrors);
    }
    updateState([
      ["loading", true],
      ["error", null],
      ["success", null],
      ["fields.name.error", null],
      ["fields.email.error", null],
      ["fields.message.error", null],
    ]);
    networkRequest({
      url: "/.netlify/functions/send-email",
      method: "POST",
      body: {
        name: localState.fields.name.value,
        email: localState.fields.email.value,
        message: localState.fields.message.value,
      },
      success: () => {
        updateState([
          ["error", null],
          ["success", "You're message has been sent! Thank you!"],
          ["fields.name.error", null],
          ["fields.email.error", null],
          ["fields.message.error", null],
        ]);
        setTimeout(() => {
          setLocalState(defaultState);
        }, 5000);
      },
      error: () => {
        updateState([
          ["loading", false],
          ["error", "An error occurred! Please try again later."],
          ["success", null],
          ["fields.name.error", null],
          ["fields.email.error", null],
          ["fields.message.error", null],
        ]);
      },
    });
  };

  const { name, email, message } = localState.fields;
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <TextField
          className={formField}
          variant="outlined"
          label="Name"
          name="name"
          fullWidth
          required
          value={name.value}
          onChange={(e) => updateState([["fields.name.value", e.target.value]])}
          error={name.error}
          helperText={name.error}
        />
        <TextField
          className={formField}
          variant="outlined"
          label="Email"
          name="email"
          fullWidth
          required
          value={email.value}
          onChange={(e) =>
            updateState([["fields.email.value", e.target.value]])
          }
          error={email.error}
          helperText={email.error}
        />
        <TextField
          className={formField}
          variant="outlined"
          label="Message"
          name="message"
          fullWidth
          required
          multiline
          minRows={6}
          value={message.value}
          onChange={(e) =>
            updateState([["fields.message.value", e.target.value]])
          }
          error={message.error}
          helperText={message.error}
        />
        {localState.error && (
          <Alert severity="error" className={formField}>
            {localState.error}
          </Alert>
        )}
        {localState.success && (
          <Alert severity="success" className={formField}>
            {localState.success}
          </Alert>
        )}
        <Grid container direction="row-reverse">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={localState.loading}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            color="default"
            onClick={() => setLocalState(defaultState)}
            disabled={localState.loading}
            style={{ marginRight: "10px" }}
          >
            Reset
          </Button>
        </Grid>
      </form>
    </Box>
  );
};
